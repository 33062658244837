import React, { useEffect, useState } from "react";

import {
  useGetExpenseTypes,
  useDeleteExpenseType,
} from "../api/ExpenseTypes.hooks";

import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";

import { components, sharedHelper } from "@crewos/shared";
import { DebounceInput } from "react-debounce-input";

// As an example, you would have your own ExpenseTypeModal
// similar to how AssetTypeModal was implemented:
import ExpenseTypeModal from "../components/ExpenseTypeModal";

const { AdvanceTable, AdvanceTablePagination, ConfirmationModal, Icon } =
  components;

const columns = (onDelete, onEdit) => [
  {
    accessor: "name",
    header: "Name",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      return rowData.row.name;
    },
  },
  {
    accessor: "expenses",
    header: "Expenses",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    type: "number",
    Cell: (rowData) => {
      // Adjust as needed depending on how your ExpenseType data is structured
      // e.g. rowData.row.expenses might be an array
      return rowData.row.expenses?.length || 0;
    },
  },
  {
    accessor: "id",
    header: "",
    disableFilter: true,
    cellProps: {
      className: "text-end",
    },
    headerProps: {
      className: "text-truncate text-end",
      style: { width: 300 },
    },
    Cell: (rowData) => (
      <>
        <Button
          size="sm"
          color="none"
          className="ms-2 text-primary"
          onClick={() => onEdit(rowData.row)}
        >
          Edit
        </Button>
        <Button
          size="sm"
          className="ms-2 text-danger"
          color="none"
          onClick={() => onDelete(rowData.row)}
        >
          Delete
        </Button>
      </>
    ),
  },
];

const INIT_PAGE_SIZE = 15;
const INIT_PAGE = 0;

const ExpenseTypes = () => {
  const [expenseTypes, setExpenseTypes] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");
  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);

  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(null);

  const {
    data: expenseTypesData,
    isLoading: isLoadingExpenseTypes,
    get: getExpenseTypes,
  } = useGetExpenseTypes();

  const { data: deleteExpenseTypeData, deleteExpenseType } =
    useDeleteExpenseType();

  // Fetching expense types
  useEffect(() => {
    getExpenseTypes({
      search,
      page,
      pageSize: sizePerPage,
    });
  }, [getExpenseTypes, search, page, sizePerPage, refresh]);

  useEffect(() => {
    if (expenseTypesData) {
      setExpenseTypes(expenseTypesData);
    }
  }, [expenseTypesData]);

  useEffect(() => {
    if (deleteExpenseTypeData) {
      sharedHelper.successToast("Expense type deleted");
      setRefresh((prev) => !prev);
    }
  }, [deleteExpenseTypeData]);

  const onEdit = (expenseType) => setUpdateModal(expenseType);

  const onDelete = (expenseType) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        await deleteExpenseType(expenseType.id);
        setConfirmationModal(null);
      },
      onClose: () => {
        setConfirmationModal(null);
        setRefresh((prev) => !prev);
      },
      title: "Delete Expense Type",
      body: `Are you sure you want to delete ${expenseType.name}?`,
      confirmColor: "danger",
      confirmText: "Delete",
    });
  };

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader className="section-header">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h2 className="mb-0">Expense Types</h2>
            <small className="text-muted ms-2 pt-1">
              ({expenseTypes.count || 0})
            </small>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="me-3">
              <DebounceInput
                className="search form-control"
                maxLength={50}
                minLength={1}
                debounceTimeout={900}
                placeholder="Search types"
                value={search}
                onChange={(evt) => {
                  setSearch(evt.target.value);
                  setPage(INIT_PAGE);
                }}
              />
            </div>
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={() => setRefresh((prev) => !prev)}
              data-testid="refresh-button"
            >
              <Icon name="refresh-cw" />
            </Button>
            <Button color="primary" onClick={() => setCreateModal(true)}>
              Create
            </Button>
          </div>
        </CardHeader>
        <CardBody className="section-body">
          <div className="overflow-x-auto">
            <AdvanceTable
              columns={columns(onDelete, onEdit)}
              data={expenseTypes.data || []}
              pageSize={sizePerPage}
              isLoading={isLoadingExpenseTypes}
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
              }}
            />
          </div>
          <AdvanceTablePagination
            totalCount={expenseTypes.count || 0}
            pageCount={expenseTypes.totalPages || 1}
            currentPage={page}
            onPageChange={(page) => setPage(page - 1)}
            pageSize={sizePerPage}
            onPageSizeChange={(sizePerPage) => {
              setSizePerPage(sizePerPage);
              setPage(INIT_PAGE);
            }}
          />
        </CardBody>
      </Card>

      {confirmationModal && <ConfirmationModal {...confirmationModal} />}

      {createModal && (
        <ExpenseTypeModal
          onClose={() => setCreateModal(false)}
          onSubmit={() => {
            setCreateModal(false);
            setRefresh((prev) => !prev);
          }}
        />
      )}
      {updateModal && (
        <ExpenseTypeModal
          expenseType={updateModal}
          onClose={() => setUpdateModal(null)}
          onSubmit={() => {
            setUpdateModal(null);
            setRefresh((prev) => !prev);
          }}
        />
      )}
    </Container>
  );
};

export default ExpenseTypes;
