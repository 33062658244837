import React from "react";

import { ListGroup, ListGroupItem } from "reactstrap";

const CustomerBasicInfo = ({ customer }) => {
  return (
    <div className="col-12 col-lg-6 px-2">
      <ListGroup flush className="col-12 px-0">
        <ListGroupItem
          className="py-2 bg-graylight border-0 border-radius-default fw-bold text-center"
          tag="div"
          key="0"
        >
          Basic Info
        </ListGroupItem>
        <ListGroupItem
          className="border-bottom py-2 px-0 mx-3 d-flex justify-content-between align-items-center"
          tag="div"
        >
          <span>Name:</span>
          <span>{customer.customerName}</span>
        </ListGroupItem>
        <ListGroupItem
          className="border-bottom py-2 px-0 mx-3 d-flex justify-content-between align-items-center"
          tag="div"
        >
          <span>Customer #:</span>
          <span>{customer.customerNo || "-"}</span>
        </ListGroupItem>
        <ListGroupItem
          className="border-bottom py-2 px-0 mx-3 d-flex justify-content-between align-items-center"
          tag="div"
        >
          <span>Email:</span>
          <span>{customer.email || "-"}</span>
        </ListGroupItem>
      </ListGroup>
    </div>
  );
};

export default CustomerBasicInfo;
