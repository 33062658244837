import React, { useCallback, useEffect, useMemo, useState } from "react";

import { DebounceInput } from "react-debounce-input";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import { components, sharedHelper } from "@crewos/shared";

import {
  useDeleteEmployeeRole,
  useGetEmployeeRoles,
} from "../../api/EmployeeRoles.hooks";

import EmployeeRoleModal from "../../components/EmployeeRoleModal";

const {
  Icon,
  Select,
  AdvanceTable,
  AdvanceTablePagination,
  InformationModal,
  ConfirmationModal,
} = components;

const columns = (onSeeEmployees, onEdit, onDelete) => [
  {
    accessor: "name",
    header: "Name",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      const { name } = rowData.row;
      return name || "-";
    },
  },
  {
    accessor: "employees",
    header: "# Employees",
    disableSortBy: true,
    type: "number",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => rowData.row.employees.length,
  },
  {
    accessor: "isActive",
    header: "Active",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => (rowData.row.isActive ? "Yes" : "No"),
  },
  {
    accessor: "id",
    header: "",
    disableFilter: true,
    headerProps: { style: { width: 300 } },
    disableSortBy: true,
    cellProps: {
      className: "text-end",
    },
    Cell: (rowData) => (
      <div className="d-flex align-items-center justify-content-end">
        <Button
          size="sm"
          color="primary"
          onClick={() => onSeeEmployees(rowData.row)}
        >
          See Employees
        </Button>
        <Button
          size="sm"
          color="none"
          className="ms-2 text-primary"
          onClick={() => onEdit(rowData.row)}
        >
          Edit
        </Button>
        <Button
          size="sm"
          className="ms-2 text-danger"
          color="none"
          data-testid="delete-button"
          onClick={() => onDelete(rowData.row)}
        >
          Delete
        </Button>
      </div>
    ),
  },
];

const INIT_PAGE_SIZE = 15;
const INIT_PAGE = 0;

const EmployeeRoles = () => {
  const [employeeRoles, setEmployeeRoles] = useState({});
  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("name");
  const [direction, setDirection] = useState("asc");
  const [refresh, setRefresh] = useState();

  const [editModal, setEditModal] = useState();
  const [createModal, setCreateModal] = useState();

  const [showInactive, setShowInactive] = useState();

  const [informationModal, setInformationModal] = useState();

  const [confirmationModal, setConfirmationModal] = useState();

  const {
    data: employeeRolesData,
    isLoading: isLoadingEmployeeRoles,
    get: getEmployeeRoles,
  } = useGetEmployeeRoles();

  const { data: deleteEmployeeRoleData, deleteEmployeeRole } =
    useDeleteEmployeeRole();

  useEffect(() => {
    if (deleteEmployeeRoleData) {
      sharedHelper.successToast(`Employee role deleted`);
      setRefresh((prev) => !prev);
    }
  }, [deleteEmployeeRoleData, setRefresh]);

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        if (data.sortBy === sortBy && data.direction === direction) {
          return;
        }
        setSortBy(data.sortBy);
        setDirection(data.direction);
        setPage(INIT_PAGE);
      } else {
        setSortBy(null);
        setDirection(null);
        setPage(INIT_PAGE);
      }
    },
    [setSortBy, setDirection, setPage, direction, sortBy]
  );

  useEffect(() => {
    getEmployeeRoles({
      search,
      page,
      pageSize: sizePerPage,
      sortBy,
      direction,
      isActive: showInactive ? "false" : "true",
    });
  }, [
    getEmployeeRoles,
    sortBy,
    direction,
    sizePerPage,
    page,
    search,
    refresh,
    showInactive,
  ]);

  useEffect(() => {
    if (employeeRolesData) {
      setEmployeeRoles(employeeRolesData);
    }
  }, [employeeRolesData, setEmployeeRoles]);

  const onEdit = (employeeRole) => setEditModal(employeeRole.id);

  const onDelete = (employeeRole) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        await deleteEmployeeRole(employeeRole.id);
        setConfirmationModal();
      },
      onClose: () => {
        setConfirmationModal();
        setRefresh((prev) => !prev);
      },
      title: "Delete Role",
      body: `Are you sure you want to delete ${employeeRole.name}?`,
      confirmColor: "danger",
      confirmText: "Delete",
    });
  };

  const onSeeEmployees = (employeeRole) => {
    setInformationModal({
      title: `Role Employees`,
      rawBody: true,
      size: "sm",
      onClose: () => setInformationModal(),
      body: (
        <ListGroup>
          {employeeRole.employees.length ? (
            employeeRole.employees.map((employee, index) => (
              <ListGroupItem key={index}>
                {`${employee.firstName} ${employee.lastName}`}
              </ListGroupItem>
            ))
          ) : (
            <ListGroupItem>No employees to show</ListGroupItem>
          )}
        </ListGroup>
      ),
    });
  };

  const activeSelect = useMemo(() => {
    return [
      { label: "Active", value: false },
      { label: "Inactive", value: true },
    ];
  }, []);

  const defaultActive = useMemo(() => {
    return activeSelect.find((option) => option.value === showInactive);
  }, [showInactive, activeSelect]);

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader className="section-header">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h2 className="mb-0 ">Employee Roles</h2>
            <small className="text-muted ms-2 pt-1">
              ({employeeRoles.count})
            </small>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="me-3">
              <Select
                id="activeSelect"
                name="activeSelect"
                onChange={(selected) => setShowInactive(selected.value)}
                value={defaultActive}
                options={activeSelect}
                placeholder="Filter by status"
              />
            </div>
            <div className="me-3">
              <DebounceInput
                className="search form-control"
                maxLength={50}
                minLength={1}
                debounceTimeout={900}
                placeholder="Search employee roles"
                value={search}
                onChange={(evt) => {
                  setSearch(evt.target.value);
                  setPage(INIT_PAGE);
                }}
              />
            </div>
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={() => setRefresh((prev) => !prev)}
            >
              <Icon name="refresh-cw" />
            </Button>
            <Button color="primary" onClick={() => setCreateModal(true)}>
              Create
            </Button>
          </div>
        </CardHeader>
        <CardBody className="section-body">
          <div className="overflow-x-auto">
            <AdvanceTable
              columns={columns(onSeeEmployees, onEdit, onDelete)}
              data={employeeRoles.data || []}
              pageSize={sizePerPage}
              sortable
              onSort={onSort}
              defaultSort={{
                sortBy,
                direction,
              }}
              isLoading={isLoadingEmployeeRoles}
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
              }}
            />
          </div>
          <AdvanceTablePagination
            totalCount={employeeRoles.count}
            pageCount={employeeRoles.totalPages}
            currentPage={page}
            onPageChange={(page) => setPage(page - 1)}
            pageSize={sizePerPage}
            onPageSizeChange={(sizePerPage) => {
              setSizePerPage(sizePerPage);
              setPage(INIT_PAGE);
            }}
          />
        </CardBody>
      </Card>
      {informationModal ? (
        <InformationModal {...informationModal} />
      ) : editModal ? (
        <EmployeeRoleModal
          employeeRoleId={editModal}
          onClose={() => setEditModal(false)}
          onSubmit={() => {
            setEditModal(false);
            setRefresh((prev) => !prev);
          }}
        />
      ) : createModal ? (
        <EmployeeRoleModal
          onClose={() => setCreateModal(false)}
          onSubmit={() => {
            setCreateModal(false);
            setRefresh((prev) => !prev);
          }}
        />
      ) : confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Container>
  );
};

export default EmployeeRoles;
