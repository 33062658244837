import ConfirmationModal from "./components/ConfirmationModal";
import DatesSelector from "./components/DatesSelector";
import InformationModal from "./components/InformationModal";
import ConfirmationModalWithInput from "./components/ConfirmationModalWithInput";
import CustomCheckbox from "./components/CustomCheckbox";
import CustomCheckboxWithLabel from "./components/CustomCheckboxWithLabel";
import WorkOrderDateSelector from "./components/WorkOrderDateSelector";
import UploadPhotosModal from "./components/UploadPhotosModal";
import DateRangeLabel from "./components/dateRange/DateRangeLabel";
import Loader from "./components/Loader";
import TooltipItem from "./components/TooltipItem";
import AdvanceTable from "./components/advanceTable/AdvanceTable";
import AdvanceTablePagination from "./components/advanceTable/AdvanceTablePagination";
import WeekSelector from "./components/WeekSelector";
import ImagesViewer from "./components/ImagesViewer";
import ImagesViewerModal from "./components/ImagesViewerModal";
import SelectAdvance from "./components/SelectAdvance";
import ListTemplateWrapper from "./components/ListTemplates/ListTemplatesWrapper";
import ListTemplatesItem from "./components/ListTemplates/ListTemplatesItem";
import ListTemplatesGrouped from "./components/ListTemplates/ListTemplatesGrouped";
import FormTemplate from "./components/FormTemplate/FormTemplate";
import InputEmail from "./components/InputEmail";
import {
  useCreateServiceLocation,
  useDeleteServiceLocation,
  useGetServiceLocations,
  useUpdateServiceLocation,
} from "./api/ServiceLocations.hooks";

import {
  useAuth,
  AuthProvider,
  ACTIONS as AUTH_ACTIONS,
} from "./providers/authProvider";

import {
  useSidebar,
  SidebarProvider,
  ACTIONS as SIDEBAR_ACTIONS,
} from "./providers/sidebarProvider";

import {
  FormTemplateProvider,
  useFormTemplate,
  useFormSections,
  ELEMENT_STATUS,
  ORIGIN_PAGE,
  FormTemplateContext,
} from "./providers/FormTemplateProvider";

import { authApi } from "./api/authServices";
import { awsApi } from "./api/awsServices";
import AdminLayout from "./layouts/Admin";
import DefaultLayout from "./layouts/Default";
import AuthLayout from "./layouts/Auth";

import { sharedHelper } from "./helpers/sharedHelper";
import { Api, HttpMethod } from "./api/Api";
import { useApiGet, useApiMutation, toastConfig } from "./api/Api.hooks";
import {
  useWorkOrderDetails,
  WorkOrderDetailsProvider,
  ACTIONS as WORK_ORDER_DETAILS_ACTIONS,
  TABS as WORK_ORDER_DETAILS_TABS,
} from "./providers/workOrderDetailsProvider";

import { useGetSettings, useUpdateSetting } from "./api/Settings.hooks";

import PlaceholderLoadingContent from "./components/PlaceholderLoadingContent";
import CustomPeriodSelector from "./components/CustomPeriodSelector";
import PeriodTypeSelector from "./components/PeriodTypeSelector";

import COLORS from "./assets/colorPalette.json";
import StatusFilter, { getStatusColor } from "./components/StatusFilter";
import ScopeFilter from "./components/ScopeFilter";
import { createRoutes } from "./helpers/routing";
import RulesModal from "./components/RulesModal";
import Icon from "./components/Icon";
import Select from "./components/Select";
import DynamicAttributeInput from "./components/dynamicAttributes/DynamicAttributeInput";
import DynamicAttributesModal from "./components/dynamicAttributes/DynamicAttributesModal";
import Settings from "./pages/Settings";
import MonthSelector from "./components/MonthSelector";
import TwoFASetupModal from "./components/twoFA/TwoFASetupModal";
import TwoFAForm from "./components/twoFA/TwoFAForm";
import DynamicAttributeLabel from "./components/dynamicAttributes/DynamicAttributeLabel";
import { useGetAllDynamicAttributes } from "./api/DynamicAttributes.hooks";

//our global data providers are work order details, auth, sidebar, packages
const data = {
  SIDEBAR_ACTIONS,
  WORK_ORDER_DETAILS_ACTIONS,
  WORK_ORDER_DETAILS_TABS,
  WorkOrderDetailsProvider,
  AuthProvider,
  SidebarProvider,
  useAuth,
  AUTH_ACTIONS,
  useWorkOrderDetails,
  useSidebar,
  FormTemplateProvider,
  useFormTemplate,
  useFormSections,
  ELEMENT_STATUS,
  ORIGIN_PAGE,
  FormTemplateContext,
  useGetAllDynamicAttributes,
};

const helpers = {
  createRoutes,
};

const layouts = {
  AdminLayout,
  DefaultLayout,
  AuthLayout,
};

const components = {
  TwoFASetupModal,
  TwoFAForm,
  RulesModal,
  DatesSelector,
  ConfirmationModal,
  ConfirmationModalWithInput,
  CustomCheckbox,
  DateRangeLabel,
  PeriodTypeSelector,
  CustomPeriodSelector,
  InformationModal,
  Loader,
  TooltipItem,
  AdvanceTable,
  AdvanceTablePagination,
  WeekSelector,
  MonthSelector,
  ImagesViewer,
  ImagesViewerModal,
  CustomCheckboxWithLabel,
  WorkOrderDateSelector,
  UploadPhotosModal,
  PlaceholderLoadingContent,
  SelectAdvance,
  StatusFilter,
  ScopeFilter,
  Icon,
  ListTemplateWrapper,
  ListTemplatesItem,
  ListTemplatesGrouped,
  FormTemplate,
  InputEmail,
  Select,
  DynamicAttributeInput,
  DynamicAttributeLabel,
  DynamicAttributesModal,
  Settings,
};

const api = {
  Api,
  HttpMethod,
  useApiGet,
  useApiMutation,
  authApi,
  useGetSettings,
  useUpdateSetting,
  useDeleteServiceLocation,
  useGetServiceLocations,
  useCreateServiceLocation,
  useUpdateServiceLocation,
};

export {
  helpers,
  layouts,
  data,
  sharedHelper,
  components,
  api,
  awsApi,
  toastConfig,
  COLORS,
  getStatusColor,
};
