import React from "react";
import { ListGroup, ListGroupItem } from "reactstrap";

const CustomerContactInfo = ({ customer }) => {
  return (
    <div className="col-12 col-lg-6 px-2 mt-4 mt-lg-0">
      <ListGroup flush className="col-12 px-0">
        <ListGroupItem
          className="py-2 bg-graylight border-0 border-radius-default fw-bold text-center"
          tag="div"
        >
          Contact Info
        </ListGroupItem>

        <div className="row">
          <ListGroup flush className="col-6 px-0">
            <ListGroupItem
              className="border-bottom py-2 d-flex justify-content-between align-items-center"
              tag="div"
            >
              <span>Address:</span>
              <span>{customer.address || "-"}</span>
            </ListGroupItem>

            <ListGroupItem
              className="border-bottom py-2 d-flex justify-content-between align-items-center"
              tag="div"
            >
              <span>City:</span>
              <span>{customer.city || "-"}</span>
            </ListGroupItem>

            <ListGroupItem
              className="border-bottom py-2 d-flex justify-content-between align-items-center"
              tag="div"
            >
              <span>State:</span>
              <span>{customer.state || "-"}</span>
            </ListGroupItem>
          </ListGroup>

          <ListGroup flush className="col-6 px-0">
            <ListGroupItem
              className="border-bottom py-2 d-flex justify-content-between align-items-center"
              tag="div"
            >
              <span>Zip Code:</span>
              <span>{customer.zipCode || "-"}</span>
            </ListGroupItem>

            <ListGroupItem
              className="border-bottom py-2 d-flex justify-content-between align-items-center"
              tag="div"
            >
              <span>Country:</span>
              <span>{customer.countryCode || "-"}</span>
            </ListGroupItem>

            <ListGroupItem
              className="border-bottom py-2 d-flex justify-content-between align-items-center"
              tag="div"
            >
              <span>Telephone:</span>
              <span>{customer.phone || "-"}</span>
            </ListGroupItem>
            <ListGroupItem
              className="border-bottom py-2 d-flex justify-content-between align-items-center"
              tag="div"
            >
              <span>Fax:</span>
              <span>{customer.faxNo || "-"}</span>
            </ListGroupItem>
          </ListGroup>
        </div>
      </ListGroup>
    </div>
  );
};

export default CustomerContactInfo;
