import { useGetWorkOrders, useGetWorkDaySummary } from "./api/WorkOrders.hooks";
import { useGetAllWorkOrderStatus } from "./api/WorkOrderStatus.hooks";
import { useGetWorkOrderWorkPerformed } from "./api/WorkOrders.hooks";

import { data, sharedHelper, helpers } from "@crewos/shared";

import WorkOrders from "./pages/WorkOrders";
import WorkOrderDetails from "./pages/WorkOrderDetails";

import WorkOrderStatus from "./pages/settings/WorkOrderStatus";

import WorkOrderStatusCategory from "./pages/settings/WorkOrderStatusCategory";

import { getSelectedDateWorkOrder } from "./helpers/workOrder";

import WorkOrderSettings from "./pages/settings/WorkOrders";

import "react-quill/dist/quill.snow.css";

const { createRoutes } = helpers;

const { SIDEBAR_ACTIONS } = data;

const WORKORDERS = "WORKORDERS";

// Routes
const routes = (workOrderStatuses = []) => [
  {
    icon: "briefcase",
    sidebar: true,
    name: "Work Orders",
    path: "/workorders",
    package: "workorders",
    entries: [
      {
        path: ``,
        sidebar: false,
        component: WorkOrders,
      },
      {
        path: `all`,
        name: "All",
        sidebar: true,
        component: WorkOrders,
      },
      {
        path: "details/:workOrderId",
        name: "Work Order Details",
        component: WorkOrderDetails,
      },
      {
        path: "details/:workOrderId/:tab",
        name: "Work Order Details",
        component: WorkOrderDetails,
      },
      {
        path: `:workOrderStatusId`,
        sidebar: false,
        component: WorkOrders,
      },
      ...workOrderStatuses.map((status) => ({
        name: `${status.name}`,
        path: `${status.id || ""}`,
        sidebar: true,
        component: WorkOrders,
      })),
    ],
  },
  {
    sidebar: true,
    icon: null,
    name: "Work Orders Settings",
    path: `workorders/work-order`,
    scopes: ["IS_SUPER_ADMIN_USER"],
    package: "workorders",
    isSetting: true,
    category: "Work Orders",
    component: WorkOrderSettings,
    order: 1,
  },
  {
    sidebar: true,
    icon: null,
    name: "Work Order Status",
    path: `workorders/work-order-status`,
    scopes: ["IS_SUPER_ADMIN_USER"],
    package: "workorders",
    isSetting: true,
    category: "Work Orders",
    component: WorkOrderStatus,
    order: 2,
  },
  {
    sidebar: true,
    icon: null,
    name: "Status Categories",
    path: `workorders/work-order-status-categories`,
    scopes: ["IS_SUPER_ADMIN_USER"],
    package: "workorders",
    isSetting: true,
    category: "Work Orders",
    component: WorkOrderStatusCategory,
    order: 3,
  },
];

const Routes = createRoutes(routes());

const setWorkOrderSidebarRoutes = ({ setSidebarContext, userData }) => {
  if (!userData) {
    return;
  }

  const packages = userData.packages || [];
  const workOrderStatus = userData.workOrderStatus || [];
  const sidebarRoutes = routes(workOrderStatus).filter(
    (route) => route.sidebar
  );

  const packageEnabled = sharedHelper.isPackageEnabled(packages, "workorders");

  if (packageEnabled) {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_ROUTES,
      payload: { routes: sidebarRoutes, name: WORKORDERS, order: 6 },
    });
  }
};

export {
  getSelectedDateWorkOrder,
  useGetWorkDaySummary,
  useGetWorkOrders,
  useGetWorkOrderWorkPerformed,
  useGetAllWorkOrderStatus,
  setWorkOrderSidebarRoutes,
  Routes as WorkOrderRoutes,
};
