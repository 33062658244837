import { createRoot } from "react-dom/client";
import moment from "moment";
import FileSaver from "file-saver";
import { toast } from "react-toastify";

import { toastConfig } from "../api/Api.hooks";
import { awsApi } from "../api/awsServices";

const ENV_BETA = "beta";
const ENV_PROD = "production";

const STATUS_APPROVED = "approved";
const STATUS_DECLINED = "declined";

const IS_APP_USER = "IS_APP_USER";

const ADD = "ADD";
const SUBTRACT = "SUBTRACT";

export const sharedHelper = {
  goBack: (navigate) => {
    try {
      navigate(-1);
    } catch (err) {
      navigate("/workorders");
    }
  },
  plusIndicatorEndTime: (startTime, endTime) =>
    startTime &&
    endTime &&
    moment(endTime, "HH:mm").isSameOrBefore(moment(startTime, "HH:mm")) ? (
      <small className="text-muted ms-1" style={{ fontSize: 10 }}>
        +1
      </small>
    ) : (
      ""
    ),

  workTimeEntryToolTipContent: (
    times,
    showExceptions,
    startEndTimeSettingEnabled
  ) => `
      <table class="mt-1 table mb-0 small">
        <thead>
          <tr>
            ${
              startEndTimeSettingEnabled
                ? `<th class="py-1 text-center"><strong>Start Time</strong></th>
                  <th class="py-1 text-center"><strong>End Time</strong></th>`
                : ""
            }
            <th class="py-1 text-center"><strong>Hours</strong></th>
            ${
              showExceptions
                ? `<th class="py-1 text-center"><strong>Exceptions</strong></th>`
                : ""
            }
          </tr>
        </thead>
        <tbody>
          ${times
            .map((time, index) => {
              const isNextDay = time.endTime
                ? moment(time.endTime).isAfter(moment(time.startTime), "date")
                : false;
              return `
              <tr>
                ${
                  startEndTimeSettingEnabled
                    ? `<td class="text-center">
                  ${sharedHelper.formatDateTime(time.startTime, "hh:mm a")}
                </td>
                <td class="text-center">
                  <div class="d-flex align-items-start justify-content-center">
                    ${
                      time.endTime
                        ? `${sharedHelper.formatDateTime(
                            time.endTime,
                            "hh:mm a"
                          )}${
                            isNextDay
                              ? `<small
                                  class="text-muted ms-1"
                                  style="font-size: 10"
                                >
                                  +1
                                </small>`
                              : ""
                          }`
                        : "In Progress"
                    }
                  </div>
                </td>`
                    : ""
                }
                <td class="text-center">
                  ${time.hours.toFixed(2)}
                </td>
                ${
                  showExceptions
                    ? `<td class="text-center">${
                        time.exceptions.length
                          ? time.exceptions.map((exception) => {
                              const action =
                                exception.action === ADD
                                  ? "+"
                                  : exception.action === SUBTRACT
                                  ? "-"
                                  : "";
                              return `<span>${action}${exception.amount} hour${
                                exception.amount > 1 ? "s" : ""
                              } of ${exception.name}</span>`;
                            })
                          : "None"
                      }</td>`
                    : ""
                }
              </tr>
            `;
            })
            .join("")}
        </tbody>
      </table>
    `,

  travelTimeToolTipContent: (times, startEndTimeSettingEnabled) => `
    <table class="mt-1 table mb-0 small">
      <thead>
        <tr>
          <th class="text-center">Travel #</th>
          ${
            startEndTimeSettingEnabled
              ? `<th class="py-1 text-center"><strong>Start Time</strong></th>
                <th class="py-1 text-center"><strong>End Time</strong></th>`
              : ""
          }
          <th class="py-1 text-center"><strong>Hours</strong></th>
          <th class="py-1 text-center"><strong>Mileage</strong></th>
        </tr>
      </thead>
      <tbody>
        ${times
          .map((time, index) => {
            const isNextDay = time.endTime
              ? moment(time.endTime).isAfter(moment(time.startTime), "date")
              : false;
            return `
            <tr>
              <td class="text-center">${index + 1}</td>
              ${
                startEndTimeSettingEnabled
                  ? `<td class="text-center">
                ${sharedHelper.formatDateTime(time.startTime, "hh:mm a")}
              </td>
              <td class="text-center">
                <div class="d-flex align-items-start justify-content-center">
                  ${
                    time.endTime
                      ? `${sharedHelper.formatDateTime(
                          time.endTime,
                          "hh:mm a"
                        )}${
                          isNextDay
                            ? `<small
                                class="text-muted ms-1"
                                style="font-size: 10"
                              >
                                +1
                              </small>`
                            : ""
                        }`
                      : "In Progress"
                  }
                </div>
              </td>`
                  : ""
              }
              <td class="text-center">
                ${time.hours.toFixed(2)}
              </td>
              <td class="text-center">
                ${time.mileage.toFixed(1)}
              </td>
            </tr>
          `;
          })
          .join("")}
      </tbody>
    </table>
  `,

  formatDateTimes: (data) =>
    typeof data === "string"
      ? data
          .replace(
            /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.\d{3}Z/g,
            (match, year, month, day, hour, minute, second) => {
              const date = new Date(
                Date.UTC(year, month - 1, day, hour, minute)
              );
              const options = {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              };
              return date.toLocaleString("en-US", options).replace(",", "");
            }
          )
          .replace(/(\d{4})-(\d{2})-(\d{2})/g, "$2/$3/$1")
      : data,

  renderComponentToNode: (Component, props) => {
    const node = document.createElement("div");
    const root = createRoot(node);
    root.render(<Component {...props} />);
    return node;
  },

  formatDateRange: (startDate, endDate) => {
    const start = moment(startDate, "YYYY-MM-DD");
    const end = moment(endDate, "YYYY-MM-DD");

    if (!start.isValid() || !end.isValid()) {
      return "Start or end date is invalid.";
    }

    const startFormat = start.format("D MMM");
    const endFormat = end.format("D MMM");

    // Check if the year changed
    const isYearDifferent = start.year() !== end.year();
    const plusOne = isYearDifferent
      ? `<span class="text-muted ms-1 small" style="font-size: 10px">+1</span>`
      : "";

    const result = `<div class="d-flex align-items-start justify-content-center"><span>${startFormat} to ${endFormat}</span>${plusOne}</div>`;
    return result;
  },

  dismissLoadingToast: () => toast.dismiss("loading-toast"),

  loadingToast: (msg = "Loading..") => {
    return toast.loading(msg, {
      ...toastConfig,
      toastId: "loading-toast",
    });
  },

  warningToast: (msg) => {
    return toast.warn(msg, {
      ...toastConfig,
      toastId: "warning-toast",
    });
  },

  successToast: (msg) => {
    return toast.success(msg, {
      ...toastConfig,
      toastId: "success-toast",
    });
  },

  errorToast: (error) => {
    const genericError = "There was an error with your request.";
    const err =
      typeof error === "string" && error.length > 0
        ? error
        : error.response?.data[0]?.msg ||
          error.response?.data?.message ||
          genericError;
    return toast.error(err, {
      ...toastConfig,
      toastId: "error-toast",
    });
  },

  userHasScope: (userRole, userRoleScopes = []) => {
    if (!userRole) {
      return false;
    }
    const matchesRolePermission = userRoleScopes.filter((x) =>
      userRole.userRoleScopes.map((scope) => scope.name).includes(x)
    ).length;
    return Boolean(matchesRolePermission);
  },

  isRoleAppUser: (role) =>
    Boolean(role.userRoleScopes.find((scope) => scope.name === IS_APP_USER)),

  /**
   * Updated routeMeetsRole to handle:
   * - route undefined
   * - no route.scopes
   * - positive scopes (OR)
   * - negated scopes with '!' (AND)
   */
  routeMeetsRole: (route, userRoleScopes = []) => {
    // If route or route.scopes is not defined or empty, return true
    if (!route?.scopes?.length) {
      return true;
    }

    const userRolePermissions = userRoleScopes.map((p) => p.name);

    // Separate positive scopes and negative scopes
    const positiveScopes = route.scopes.filter(
      (scope) => !scope.startsWith("!")
    );
    const negativeScopes = route.scopes
      .filter((scope) => scope.startsWith("!"))
      .map((scope) => scope.replace("!", ""));

    // For the positive scopes, user must match at least one (OR)
    // If no positive scopes exist, consider it automatically true
    const meetsPositive = positiveScopes.length
      ? positiveScopes.some((posScope) =>
          userRolePermissions.includes(posScope)
        )
      : true;

    // For the negative scopes, user must not have any of them (AND)
    const meetsNegative = negativeScopes.every(
      (negScope) => !userRolePermissions.includes(negScope)
    );

    return meetsPositive && meetsNegative;
  },

  sortEmployeeCrew: (x, y) => {
    // 1. Leads come first
    if (x.isLead !== y.isLead) {
      return y.isLead - x.isLead;
    }
    // 2. For non-leads, sort by role name in descending order.
    if (x.role.name !== y.role.name) {
      // if x.role.name is lexicographically greater, then x should come first (descending order)
      return x.role.name > y.role.name ? -1 : 1;
    }
    // 3. If role names are equal, sort by firstName in ascending order.
    if (x.employee && y.employee) {
      if (x.employee.firstName < y.employee.firstName) return -1;
      if (x.employee.firstName > y.employee.firstName) return 1;
    }
    return 0;
  },

  isSettingEnabled: (packages = [], settingName) => {
    const setting = packages
      .flatMap((p) => p.settings)
      .find((s) => s.name === settingName);
    return setting?.status;
  },

  getSettingValue: (packages = [], pkgName, settingName) => {
    const pkg = packages.find((p) => p.path === pkgName);
    const setting = pkg?.settings.find((s) => s.name === settingName);
    return setting?.value;
  },

  isPackageEnabled: (packages = [], pack) => {
    if (pack === "core") {
      return true;
    }
    const parentIsActive = (p) => {
      const parentPackage = packages.find((pp) => pp.id === p.parentPackageId);
      return parentPackage ? parentPackage.isActive : true;
    };
    const packagesPath = packages
      .filter((p) => p.isActive && parentIsActive(p))
      .map((p) => p.path);
    if (typeof pack === "object") {
      // array
      return pack.some((e) => packagesPath.indexOf(e) > -1);
    }
    return packagesPath.indexOf(pack) > -1;
  },

  isProd: () => process.env.REACT_APP_ENV === ENV_PROD,
  isProdOrBeta: () =>
    process.env.REACT_APP_ENV === ENV_BETA ||
    process.env.REACT_APP_ENV === ENV_PROD,

  buildQueryString: (data) =>
    Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&"),

  formatMileage: (mileage, toFixed) => `${mileage.toFixed(toFixed)} m.`,

  formatCurrency: (number, maximumFractionDigits = 2) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits,
    }).format(number),

  formatDecimal: (number, maximumFractionDigits = 2) =>
    new Intl.NumberFormat("en-US", { maximumFractionDigits }).format(number),

  formatPercent: (progress, total) =>
    `${total ? ((progress / total) * 100).toFixed(2) : 0}%`,

  formatDate: (date, format = "YYYY-MM-DD") =>
    (date ? moment(date) : moment()).format(format),

  formatDateTime: (date, format = "MM/DD/YYYY, h:mm a") =>
    (date ? moment(date) : moment()).format(format),

  capitalize: (text) =>
    text.charAt(0).toUpperCase() + text.toLowerCase().slice(1),

  validateEmail: (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  },

  timeFromSeconds: (secs, separator) => {
    const pad = (num) => ("0" + num).slice(-2);
    let minutes = Math.floor(secs / 60);
    const hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    secs = secs % 60;
    return separator
      ? `${pad(hours)}:${pad(minutes)}:${pad(secs)}`
      : `${hours}h ${pad(minutes)}min`;
  },

  formatTime: (seconds) => {
    const minutes = Math.floor(parseInt(seconds) / 60);
    const hh = Math.floor(minutes / 60);
    const mm = minutes % 60;
    return `${hh < 10 ? `0${hh}` : hh}h ${mm < 10 ? `0${mm}` : mm}m`;
  },

  getName: (person) => `${person.firstName} ${person.lastName}`,

  getAddress: (customerLocation) => {
    const address = [
      customerLocation?.shipToAddress,
      customerLocation?.shipToCity,
      customerLocation?.shipToState,
      customerLocation?.shipToZipCode,
    ]
      .filter(Boolean)
      .join(", ");
    return `${customerLocation?.shipToName} ${address.length ? address : ""}`;
  },

  getWorkDaysGroupedByDate: (workdays) => {
    const days = workdays.reduce((p, c) => {
      if (p[c.date]) {
        p[c.date].push(c);
      } else {
        p[c.date] = [c];
      }
      return p;
    }, {});
    return days;
  },

  getExpenseStatusText: (expense) =>
    expense.status === STATUS_DECLINED
      ? `Declined by ${expense.declinedAuthor.firstName} ${
          expense.declinedAuthor.lastName
        } at ${sharedHelper.formatDateTime(expense.declinedAt)}`
      : expense.status === STATUS_APPROVED
      ? `Approved by ${expense.approvedAuthor.firstName} ${
          expense.approvedAuthor.lastName
        } at ${sharedHelper.formatDateTime(expense.approvedAt)}`
      : null,

  downloadFile: async (item, fileName) => {
    if (typeof item === "string") {
      const secureURL = item.replace("http://", "https://");
      FileSaver.saveAs(secureURL, fileName);
    } else {
      FileSaver.saveAs(item, fileName);
    }
  },

  uploadFile: async (files) => {
    const formData = new FormData();
    formData.append("bucket", process.env.REACT_APP_IMAGES_BUCKET_NAME);
    files.forEach((file) => formData.append("files", file));
    const response = await awsApi.uploadFile(formData);
    return response;
  },
};
