import React, { useCallback, useEffect, useState } from "react";

import { DebounceInput } from "react-debounce-input";

import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";

import { components, sharedHelper, api, data } from "@crewos/shared";

import ServiceLocationModal from "../../components/ServiceLocationModal";

const { useDeleteServiceLocation, useGetServiceLocations } = api;
const { useAuth, AUTH_ACTIONS } = data;

const { AdvanceTable, AdvanceTablePagination, Icon, ConfirmationModal } =
  components;

const columns = (onSeeDetails, onDelete) => [
  {
    accessor: "name",
    header: "Name",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      const { name } = rowData.row;
      return name || "-";
    },
  },
  {
    accessor: "workOrders",
    header: "# Work Orders",
    type: "number",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => rowData.row.workOrders,
  },
  {
    accessor: "users",
    header: "# Users",
    type: "number",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => rowData.row.users,
  },
  {
    accessor: "employees",
    header: "# Employees",
    type: "number",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => rowData.row.employees,
  },
  {
    accessor: "id",
    header: "",
    disableFilter: true,
    headerProps: { style: { width: 300 } },
    disableSortBy: true,
    cellProps: {
      className: "text-end",
    },
    Cell: (rowData) => (
      <div className="d-flex align-items-center justify-content-end">
        <Button
          size="sm"
          color="primary"
          onClick={() => onSeeDetails(rowData.row)}
        >
          See Details
        </Button>
        <Button
          size="sm"
          className="ms-2 text-danger"
          color="none"
          onClick={() => onDelete(rowData.row)}
        >
          Delete
        </Button>
      </div>
    ),
  },
];

const INIT_PAGE_SIZE = 15;
const INIT_PAGE = 0;

const ServiceLocations = () => {
  const [, setAuthContext] = useAuth();
  const [serviceLocations, setServiceLocations] = useState({});
  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("name");
  const [direction, setDirection] = useState("asc");
  const [refresh, setRefresh] = useState();

  const [editModal, setEditModal] = useState();
  const [createModal, setCreateModal] = useState();

  const [confirmationModal, setConfirmationModal] = useState();

  const {
    data: serviceLocationsData,
    isLoading: isLoadingServiceLocations,
    get: getServiceLocations,
  } = useGetServiceLocations();

  const { data: deleteServiceLocationData, deleteServiceLocation } =
    useDeleteServiceLocation();

  useEffect(() => {
    if (deleteServiceLocationData) {
      sharedHelper.successToast(`Service Location deleted`);
      setAuthContext({
        action: AUTH_ACTIONS.REFRESH,
      });
    }
  }, [deleteServiceLocationData, setAuthContext]);

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        if (data.sortBy === sortBy && data.direction === direction) {
          return;
        }
        setSortBy(data.sortBy);
        setDirection(data.direction);
        setPage(INIT_PAGE);
      } else {
        setSortBy(null);
        setDirection(null);
        setPage(INIT_PAGE);
      }
    },
    [setSortBy, setDirection, setPage, direction, sortBy]
  );

  useEffect(() => {
    getServiceLocations({
      search,
      page,
      pageSize: sizePerPage,
      sortBy,
      direction,
    });
  }, [
    getServiceLocations,
    sortBy,
    direction,
    sizePerPage,
    page,
    search,
    refresh,
  ]);

  useEffect(() => {
    if (serviceLocationsData) {
      setServiceLocations(serviceLocationsData);
    }
  }, [serviceLocationsData, setServiceLocations]);

  const onDelete = (serviceLocation) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        await deleteServiceLocation(serviceLocation.id);
        setConfirmationModal();
      },
      onClose: () => {
        setConfirmationModal();
      },
      title: "Delete Role",
      body: `Are you sure you want to delete ${serviceLocation.name}?`,
      confirmColor: "danger",
      confirmText: "Delete",
    });
  };

  const onSeeDetails = (serviceLocation) => {
    setEditModal(serviceLocation);
  };

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader className="section-header">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h2 className="mb-0 ">Service Locations</h2>
            <small className="text-muted ms-2 pt-1">
              ({serviceLocations.count || 0})
            </small>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="me-3">
              <DebounceInput
                className="search form-control"
                maxLength={50}
                minLength={1}
                debounceTimeout={900}
                placeholder="Search something"
                value={search}
                onChange={(evt) => {
                  setSearch(evt.target.value);
                  setPage(INIT_PAGE);
                }}
              />
            </div>
            <Button
              aria-label="Refresh"
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={() => setRefresh((prev) => !prev)}
            >
              <Icon name="refresh-cw" />
            </Button>
            <Button color="primary" onClick={() => setCreateModal(true)}>
              Create
            </Button>
          </div>
        </CardHeader>
        <CardBody className="section-body">
          <div className="overflow-x-auto">
            <AdvanceTable
              columns={columns(onSeeDetails, onDelete)}
              data={serviceLocations.data || []}
              pageSize={sizePerPage}
              sortable
              onSort={onSort}
              defaultSort={{
                sortBy,
                direction,
              }}
              isLoading={isLoadingServiceLocations}
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
              }}
            />
          </div>
          <AdvanceTablePagination
            totalCount={serviceLocations.count}
            pageCount={serviceLocations.totalPages}
            currentPage={page}
            onPageChange={(page) => setPage(page - 1)}
            pageSize={sizePerPage}
            onPageSizeChange={(sizePerPage) => {
              setSizePerPage(sizePerPage);
              setPage(INIT_PAGE);
            }}
          />
        </CardBody>
      </Card>
      {editModal ? (
        <ServiceLocationModal
          serviceLocation={editModal}
          onClose={() => setEditModal(false)}
          onSubmit={() => {
            setEditModal(false);
            setAuthContext({
              action: AUTH_ACTIONS.REFRESH,
            });
          }}
        />
      ) : createModal ? (
        <ServiceLocationModal
          onClose={() => setCreateModal(false)}
          onSubmit={() => {
            setCreateModal(false);
            setAuthContext({
              action: AUTH_ACTIONS.REFRESH,
            });
          }}
        />
      ) : confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Container>
  );
};

export default ServiceLocations;
