import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

import { Card, CardBody, CardHeader, Container } from "reactstrap";

import { components, sharedHelper } from "@crewos/shared";

import { useGetCustomers } from "../api/Customers.hooks";
import CustomerBasicInfo from "../components/customerDetails/CustomerBasicInfo";
import CustomerContactInfo from "../components/customerDetails/CustomerContactInfo";
import CustomerLocations from "../components/customerDetails/CustomerLocations";
import CustomerWorkOrders from "../components/customerDetails/CustomerWorkOrders";

const { Loader, Icon } = components;

const CustomerDetails = () => {
  const { customerId } = useParams();

  const [customerData, setCustomerData] = useState();

  const [refresh, setRefresh] = useState();

  const navigate = useNavigate();

  const {
    data: customer,
    isLoading: isLoadingCustomers,
    get: getCustomers,
  } = useGetCustomers();

  useEffect(() => {
    getCustomers({ id: customerId });
  }, [getCustomers, customerId, refresh]);

  useEffect(() => {
    if (customer) {
      setCustomerData(customer);
    }
  }, [customer, setCustomerData]);

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader className="section-header">
          <div className="d-flex col-12 px-2 text-dark">
            <div
              className="d-flex flex-column justify-content-center cursor-pointer"
              onClick={() => sharedHelper.goBack(navigate)}
              data-testid="back-button"
            >
              <Icon
                name="chevron-left"
                className="me-2 text-muted rounded-circle back-arrow bg-white fs-4"
              />
            </div>
            <h2 className="mb-0">{customerData?.customerName}</h2>
          </div>
        </CardHeader>
        <CardBody className="section-body">
          {isLoadingCustomers ? (
            <Loader />
          ) : customerData ? (
            <div className="flex flex-column">
              <div className="d-flex flex-wrap">
                <CustomerBasicInfo customer={customerData} />
                <CustomerContactInfo customer={customerData} />
              </div>
              <div className="d-flex flex-wrap mt-4">
                <CustomerLocations
                  customer={customerData}
                  setRefresh={setRefresh}
                />
                <CustomerWorkOrders customer={customerData} />
              </div>
            </div>
          ) : (
            <div className="text-center">No results</div>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default CustomerDetails;
