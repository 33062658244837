import React, { useCallback, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Table } from "reactstrap";

import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import moment from "moment";

import { sharedHelper } from "../../helpers/sharedHelper";
import Icon from "../Icon";
import FilterModal from "./FilterModal";

let exportClassName;

const AdvanceTable = ({
  onRowClick,
  columns = [],
  data = [],
  isLoading,
  onSort,
  defaultSort = {},
  headerClassName = "",
  rowClassName = "",
  bodyClassName = "",
  tableProps = {},
  exportable = false,
  sortable = false,
  exportName = "export.csv",
  currentColumnOrder = null,
  onColumnSort = () => {},
  isColumnSortable = false,
}) => {
  // Track current sorting state
  const [currentSort, setCurrentSort] = useState(defaultSort);

  // Track which column is selected for filtering
  const [selectedFilterColumn, setSelectedFilterColumn] = useState(null);

  // Holds the active filters for each column
  const [filters, setFilters] = useState({});

  // Manage which columns to show, in which order
  const [columnOrder, setColumnOrder] = useState(
    currentColumnOrder || columns.map((col) => col.accessor)
  );

  // State for expanded rows (for hierarchical/children data)
  const [expandedRows, setExpandedRows] = useState({});

  // Toggle expand/collapse state for a given row ID
  const toggleRowExpanded = (rowId) => {
    setExpandedRows((prev) => ({ ...prev, [rowId]: !prev[rowId] }));
  };

  // If there's a specific column order from props, update local state
  useEffect(() => {
    if (currentColumnOrder) {
      setColumnOrder(currentColumnOrder);
    }
  }, [currentColumnOrder]);

  // Sensors for drag-and-drop column reordering
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  // Helper to retrieve the cell value (handles custom Cell or nested fields)
  const getCellValue = useCallback(
    (row, accessor, rowIndex, isRaw) => {
      const column = columns.find((col) => col.accessor === accessor);
      if (column && column.Cell) {
        const value = column.Cell({ row, rowIndex });
        // If we want the “raw” data from a custom Cell, we check data-value
        if (isRaw && value?.props?.["data-value"]) {
          return value.props["data-value"];
        }
        return value;
      }

      // Handle nested accessors like "customer.customerName"
      const isNested = accessor.includes(".");
      let cellValue;
      if (isNested) {
        cellValue = accessor
          .split(".")
          .reduce((acc, key) => (acc ? acc[key] : undefined), row);
      } else {
        cellValue = row[accessor];
      }
      return cellValue ?? "";
    },
    [columns]
  );

  // Recursively render rows and their children (if any)
  const renderRows = (rows, depth = 0) => {
    return rows.map((row, rowIndex) => {
      // Unique row ID; fallback if row.id is absent
      const rowId = row.id ?? `row-${rowIndex}`;
      const isExpanded = !!expandedRows[rowId];
      const hasChildren = row.children && row.children.length > 0;

      return (
        <React.Fragment key={rowId}>
          <tr
            data-testid={`table-row-${rowId}`}
            onClick={onRowClick ? () => onRowClick(row) : null}
            className={onRowClick ? "cursor-pointer" : ""}
          >
            {columnOrder.map((accessor, colIndex) => {
              const column = columns.find((col) => col.accessor === accessor);
              if (!column) return null;

              // Indent only if it's the first column
              const isFirstColumn = colIndex === 0;
              const indentStyle = isFirstColumn
                ? {
                    paddingLeft: depth * 20 || "0.5rem",
                  }
                : {};

              // If it's the first column and there are children, display expand/collapse icon
              if (isFirstColumn) {
                return (
                  <td
                    key={column.accessor}
                    className={column.cellProps?.className}
                    style={{
                      ...indentStyle,
                      ...(column.cellProps?.style || {}),
                    }}
                  >
                    {hasChildren && (
                      <Icon
                        name={isExpanded ? "arrow-down" : "arrow-right"}
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleRowExpanded(rowId);
                        }}
                        style={{ marginRight: 8, cursor: "pointer" }}
                      />
                    )}
                    {getCellValue(row, accessor, rowIndex)}
                  </td>
                );
              }

              // For other columns, no indentation or expand icon
              return (
                <td
                  key={column.accessor}
                  className={column.cellProps?.className}
                  style={{
                    ...(column.cellProps?.style || {}),
                  }}
                >
                  {getCellValue(row, accessor, rowIndex)}
                </td>
              );
            })}
          </tr>
          {isExpanded && hasChildren && renderRows(row.children, depth + 1)}
        </React.Fragment>
      );
    });
  };

  // Filter the data if filters are active
  const filteredData = data.filter((row, rowIndex) => {
    // For each filter, check if row matches
    return Object.keys(filters).every((accessor) => {
      const filter = filters[accessor];
      if (!filter) return true;

      const cellValue = getCellValue(row, accessor, rowIndex, true).toString();
      if (filter.mode === "selectItems") {
        if (filter.values && filter.values.length > 0) {
          return filter.values.includes(cellValue);
        }
        return false;
      } else if (filter.mode === "labelFilter") {
        const valueStr = cellValue.toLowerCase();
        const filterValue = filter.value.toLowerCase();

        switch (filter.condition) {
          case "contains":
            return valueStr.includes(filterValue);
          case "doesNotContain":
            return !valueStr.includes(filterValue);
          case "equals":
            return valueStr === filterValue;
          case "notEqual":
            return valueStr !== filterValue;
          case "startsWith":
            return valueStr.startsWith(filterValue);
          case "endsWith":
            return valueStr.endsWith(filterValue);
          default:
            return true;
        }
      } else if (filter.mode === "valueFilter") {
        const valueNum = parseFloat(cellValue);
        const filterValue = parseFloat(filter.value);
        const filterValueTo = parseFloat(filter.valueTo);

        switch (filter.condition) {
          case "equals":
            return valueNum === filterValue;
          case "notEqual":
            return valueNum !== filterValue;
          case "greaterThan":
            return valueNum > filterValue;
          case "lessThan":
            return valueNum < filterValue;
          case "between":
            return valueNum >= filterValue && valueNum <= filterValueTo;
          default:
            return true;
        }
      }
      return true;
    });
  });

  // Handle column drag-and-drop
  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = columnOrder.indexOf(active.id);
      const newIndex = columnOrder.indexOf(over.id);
      const newColumnOrder = arrayMove(columnOrder, oldIndex, newIndex);
      setColumnOrder(newColumnOrder);
      onColumnSort(newColumnOrder);
    }
  };

  // Handle sorting when header is clicked
  const handleSort = (column) => {
    if (!sortable || column.disableSortBy) return;

    let newSort = { sortBy: column.accessor, direction: "asc" };

    if (currentSort.sortBy === column.accessor) {
      if (currentSort.direction === "asc") {
        newSort = { sortBy: column.accessor, direction: "desc" };
      } else if (currentSort.direction === "desc") {
        newSort = { sortBy: null, direction: null };
      }
    }

    setCurrentSort(newSort);
    onSort([newSort]);
  };

  // Build data for CSV export
  const getExportData = useCallback(() => {
    const exportHeaders = columns
      .map((column) => {
        if (!column.disableExport && column.accessor !== "id") {
          return { label: column.header, key: column.accessor };
        }
        return null;
      })
      .filter(Boolean);

    const exportData = data.map((row, rowIndex) => {
      const rowOutput = {};
      exportHeaders.forEach((hdr) => {
        rowOutput[hdr.key] = getCellValue(row, hdr.key, rowIndex, true);
      });
      return rowOutput;
    });

    return { exportHeaders, exportData };
  }, [columns, data, getCellValue]);

  // Render CSV link
  useEffect(() => {
    if (exportable) {
      const { exportHeaders, exportData } = getExportData();
      let element;
      try {
        element = document.getElementById("table-export");
      } catch (err) {
        /* no-op */
      }
      if (element) {
        exportClassName = exportClassName || element.getAttribute("data-class");
        element.replaceWith(
          sharedHelper.renderComponentToNode(CSVLink, {
            id: "table-export",
            data: exportData,
            headers: exportHeaders,
            filename: `${moment().format("YYYY-MM-DD HH:mm:ss")}_${exportName}`,
            className: `btn btn-white text-primary btn-sm rounded-circle d-flex custom-rounded-button py-2 ${
              exportClassName || ""
            }`,
            children: <Icon name="download" data-testid="fa-icon-download" />,
          })
        );
      }
    }
  }, [exportable, columns, data, exportName, getExportData]);

  // Sortable header cell for drag-and-drop
  const SortableHeaderCell = ({ id, column }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({ id });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      cursor: isColumnSortable && !column.disableSortBy ? "move" : "default",
      backgroundColor: isDragging ? "rgba(0,0,0,0.05)" : undefined,
      opacity: isDragging ? 0.8 : 1,
      ...(column.headerProps?.style || {}),
    };

    const className = `draggable ${column.headerProps?.className || ""} ${
      isDragging ? "is-dragging" : ""
    }`;

    const textAlign = className.includes("text-center")
      ? "center"
      : className.includes("text-end")
      ? "end"
      : "start";

    return (
      <th
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        className={className}
        onClick={() => handleSort(column)}
        tabIndex={0}
        role="columnheader"
        aria-grabbed={isDragging}
      >
        {/* Keep the header icon and text on one line */}
        <div
          className={`d-flex align-items-center justify-content-${textAlign} flex-nowrap`}
          style={{ whiteSpace: "nowrap" }}
        >
          {!column.disableFilter && (
            <Icon
              size="xs"
              name="settings"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedFilterColumn(column);
              }}
              style={{ cursor: "pointer" }}
              data-testid={`fa-icon-gear-${column.accessor}`}
            />
          )}
          <span className="mx-1">{column.header}</span>
          {sortable && !column.disableSortBy && (
            <span
              className={`d-flex sort ${
                currentSort.sortBy === column.accessor
                  ? currentSort.direction
                  : ""
              }`}
            >
              {currentSort.sortBy === column.accessor ? (
                currentSort.direction === "desc" ? (
                  <Icon size="xs" name="arrow-down" className="text-dark" />
                ) : (
                  <Icon size="xs" name="arrow-up" className="text-dark" />
                )
              ) : (
                <Icon name="arrow" className="text-dark" />
              )}
            </span>
          )}
        </div>
      </th>
    );
  };

  return (
    <>
      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        <Table
          {...tableProps}
          className={`advance-table ${tableProps.className || ""}`}
        >
          <thead className={headerClassName}>
            {isColumnSortable ? (
              <SortableContext
                items={columnOrder}
                strategy={horizontalListSortingStrategy}
              >
                <tr>
                  {columnOrder.map((accessor) => {
                    const column = columns.find(
                      (col) => col.accessor === accessor
                    );
                    if (!column) return null;
                    return (
                      <SortableHeaderCell
                        key={column.accessor}
                        id={column.accessor}
                        column={column}
                      />
                    );
                  })}
                </tr>
              </SortableContext>
            ) : (
              <tr>
                {columnOrder.map((accessor) => {
                  const column = columns.find(
                    (col) => col.accessor === accessor
                  );
                  if (!column) return null;

                  const className = column.headerProps?.className || "";
                  const textAlign = className.includes("text-center")
                    ? "center"
                    : className.includes("text-end")
                    ? "end"
                    : "start";

                  return (
                    <th
                      key={column.accessor}
                      onClick={() => handleSort(column)}
                      className={className}
                      style={{
                        cursor: column.disableSortBy ? "default" : "pointer",
                        ...(column.headerProps?.style || {}),
                      }}
                    >
                      <div
                        className={`d-flex align-items-center justify-content-${textAlign} flex-nowrap`}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {!column.disableFilter && (
                          <Icon
                            size="xs"
                            name="settings"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedFilterColumn(column);
                            }}
                            style={{ cursor: "pointer" }}
                            data-testid={`fa-icon-gear-${column.accessor}`}
                          />
                        )}
                        <span className="mx-1">{column.header}</span>
                        {sortable && !column.disableSortBy && (
                          <span
                            className={`d-flex sort ${
                              currentSort.sortBy === column.accessor
                                ? currentSort.direction
                                : ""
                            }`}
                          >
                            {currentSort.sortBy === column.accessor ? (
                              currentSort.direction === "desc" ? (
                                <Icon name="arrow-down" />
                              ) : (
                                <Icon name="arrow-up" />
                              )
                            ) : (
                              <Icon name="arrow" />
                            )}
                          </span>
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            )}
          </thead>
          <tbody className={`condensed ${bodyClassName}`}>
            {isLoading ? (
              <tr>
                <td colSpan={columns.length} className="text-center">
                  Loading...
                </td>
              </tr>
            ) : filteredData.length === 0 ? (
              <tr>
                <td
                  colSpan={columns.length}
                  className="text-center small text-muted"
                >
                  No data to display
                </td>
              </tr>
            ) : (
              renderRows(filteredData)
            )}
          </tbody>
        </Table>
      </DndContext>

      {selectedFilterColumn && (
        <FilterModal
          toggle={() => setSelectedFilterColumn(null)}
          columnAccessor={selectedFilterColumn.accessor}
          columnHeader={selectedFilterColumn.header}
          columnValues={[
            ...new Set(
              data
                .map((row, rowIndex) =>
                  getCellValue(
                    row,
                    selectedFilterColumn.accessor,
                    rowIndex,
                    true
                  )
                )
                .filter((v) => v != null)
            ),
          ]}
          onApply={(col, filterData) => {
            setFilters({ ...filters, [col]: filterData });
          }}
          currentFilter={filters[selectedFilterColumn.accessor]}
        />
      )}
    </>
  );
};

export default AdvanceTable;
